import lodash from "lodash"
interface CacheItem<TItem> {
    value: TItem
    time: number
}

export class Cache<TItem, TKey = string> {
    map = new Map<TKey, CacheItem<TItem>>()
    searchByIds: (ids: TKey[]) => Promise<TItem[]>
    idSelector: (item: TItem) => TKey

    constructor(
        searchByIds: (ids: TKey[]) => Promise<TItem[]>,
        idSelector: (item: TItem) => TKey
    ) {
        this.searchByIds = searchByIds
        this.idSelector = idSelector
    }

    async updateByIds(ids: TKey[], force?: boolean) {
        const limit = new Date().valueOf() - 10000
        ids = lodash(ids)
            .uniq()
            .filter(
                id =>
                    !this.map.has(id) ||
                    this.map.get(id)!.time < limit ||
                    !!force
            )
            .value()

        if (!ids.length) return

        const idChunks = lodash(ids)
            .chunk(200)
            .value()

        for (let index = 0; index < idChunks.length; index++) {
            const ids = idChunks[index]
            const items = await this.searchByIds(ids)
            const time = new Date().valueOf()
            items.forEach(item => {
                this.map.set(this.idSelector(item), {
                    value: item,
                    time
                })
            })
        }
    }

    get(id: TKey) {
        return this.map.get(id)?.value
    }
}
