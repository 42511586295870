import * as XLSX from "xlsx"
const {
    read,
    utils: { sheet_to_json },
} = XLSX

export class WorkBookDto {
    book: XLSX.WorkBook
    constructor(book: XLSX.WorkBook) {
        this.book = book
    }

    getSheetByName<T = any>(name: string, opts?: XLSX.Sheet2JSONOpts) {
        return sheet_to_json<T>(this.book.Sheets[name], opts)
    }

    getSheetByIndex<T = any>(index: number, opts?: XLSX.Sheet2JSONOpts) {
        return sheet_to_json<T>(
            this.book.Sheets[this.book.SheetNames[index]],
            opts
        )
    }
}

/**解析xlsx檔案 */
export function readFileToWorkbook(file: File) {
    return new Promise<WorkBookDto>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            try {
                const data = e.target!.result
                const wb = read(data, { type: "binary" })
                resolve(new WorkBookDto(wb))
            } catch (error) {
                reject(error)
            }
        }
        reader.readAsBinaryString(file)
    })
}

/** 調整 sheet 的欄位寬度
 * 參考 https://github.com/SheetJS/sheetjs/issues/1473#issuecomment-580648494
 * 本方法依據字元數量而非字串實際長度進行欄位寬度調整
 */
export function autofitColumns(worksheet: XLSX.WorkSheet) {
    const json = XLSX.utils.sheet_to_json(worksheet) as {
        [index: string]: any
    }[]
    // 這方法遇到僅有欄位而沒有數據時會發生錯誤
    if (json.length === 0) return

    const jsonKeys = Object.keys(json[0])

    let objectMaxLength: any[] = []
    for (let i = 0; i < json.length; i++) {
        let value = json[i]
        for (let j = 0; j < jsonKeys.length; j++) {
            if (typeof value[jsonKeys[j]] == "number") {
                objectMaxLength[j] = 5
            } else {
                const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0

                objectMaxLength[j] =
                    objectMaxLength[j] >= l ? objectMaxLength[j] : l
            }
        }

        let key = jsonKeys
        for (let j = 0; j < key.length; j++) {
            objectMaxLength[j] =
                objectMaxLength[j] >= key[j].length
                    ? objectMaxLength[j]
                    : key[j].length
        }
    }
    const wscols = objectMaxLength.map((w) => {
        return { width: w * 2.75 }
    })
    worksheet["!cols"] = wscols
}
