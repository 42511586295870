export function buildParms(query: {
    [key: string]:
        | string
        | number
        | boolean
        | null
        | undefined
        | (string | number | boolean)[]
}) {
    const params = new URLSearchParams()
    Object.keys(query).map((key) => {
        const value = query[key]
        if (value === null || value === undefined || value === "") return
        if (typeof value === "object") {
            value.forEach((element) => {
                params.append(key, element.toString())
            })
        } else {
            params.append(key, value.toString())
        }
    })
    return params
}
