import ky from "ky"
import { accessToken } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
} from "@/services/DialogService"
import { login } from "@/services/AuthService"
import { buildParms } from "@/utilities/SearchParmsBuilder"
import { Cache } from "@/utilities/Cache"
import * as xlsx from "xlsx"
import dayjs from "dayjs"
import { autofitColumns } from "@/utilities/XLSXParser"
import lodash from "lodash"
import contentDisposition from "content-disposition"
import { saveAs } from "file-saver"
import { mileageNumberFormatter } from "@/utilities/Formatter"

const api = ky.create({
    prefixUrl: env.SPACE_OBJECT_SERVICE,
    timeout: 30000,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            },
        ],
    },
})

export type SpaceObjectType = "SPACE" | "EQUIP"

export interface SpaceObjectDTO {
    id: string
    parentId: string | null
    updatedTime: string
    number: string
    name: string
    type: SpaceObjectType
    labels: Label[]
    coordinate: Coordinate | null
    bimId: string | null
    category: string | null
    mileage: Mileage | null
    spaceObjectPath: SpaceObjectPath
    customProperties: CustomProperty[]
    isDisabled: boolean
}

export interface SpaceObjectPath {
    depth: number
    idPath: string[]
    namePath: string[]
    numberPath: string[]
}

export interface Label {
    name: string
    value: string | number | boolean | null
}

export interface Coordinate {
    x: number
    y: number
}

export interface Mileage {
    type: MileageType
    road: string | null
    value: number | null
    start: number | null
    end: number | null
}

export enum MileageType {
    Point,
    Range,
}

export enum SpaceObjectSortMethod {
    Name,
    Number,
    NameWithDepthFirst,
    NumberWithDepthFirst,
    NameWithBreadthFirst,
    NumberWithBreadthFirst,
}

export interface CustomProperty {
    name: string
    value: string
}

/** 查詢空間物件 */
export function getSpaceObjects(
    query: {
        skip?: number | null
        take?: number | null
        ids?: string[] | null
        keyword?: string | null
        types?: SpaceObjectType[] | null
        sort?: SpaceObjectSortMethod
        parentIds?: string[]
        ancestorIds?: string[]
        exceptAncestorIds?: string[]
        mileageStart?: number | null
        mileageEnd?: number | null
        notDisabled?: boolean | null
    } = {}
) {
    return api
        .get("api/SpaceObjects", {
            searchParams: buildParms(query),
        })
        .json<{
            total: number
            items: SpaceObjectDTO[]
        }>()
}

/** 取得空間物件 */
export function getSpaceObject(id: string) {
    return api.get(`api/SpaceObjects/${id}`).json<SpaceObjectDTO>()
}

/** 新增空間物件 */
export function postSpaceObject(input: PutSpaceObjectInput) {
    return api
        .post(`api/SpaceObjects`, {
            json: input,
        })
        .json<SpaceObjectDTO>()
}

interface PutSpaceObjectInput {
    id?: string | null
    parentId?: string | null
    number?: string | null
    name: string
    category?: string | null
    mileage?: Mileage | null
    type: SpaceObjectType
    coordinate?: Coordinate | null
    bimId?: string | null
    labels?: Label[] | null
    customProperties?: CustomProperty[] | null
}

/** 異動空間物件 */
export function putSpaceObject(input: PutSpaceObjectInput) {
    return api
        .put(`api/SpaceObjects/${input.id}`, {
            json: input,
        })
        .json<SpaceObjectDTO>()
}

/** 設定空間物件 */
export function setSpaceObject(input: PutSpaceObjectInput) {
    return api
        .put(`api/SpaceObjects`, {
            json: input,
        })
        .json<SpaceObjectDTO>()
}

export function spaceObjectTypeFormatter(value: SpaceObjectType) {
    switch (value) {
        case "SPACE":
            return "空間"
        case "EQUIP":
            return "設備"
        default:
            return ""
    }
}

export function parseSpaceObjectType(value: string): SpaceObjectType | null {
    switch (value) {
        case "空間":
            return "SPACE"
        case "設備":
            return "EQUIP"
        default:
            return null
    }
}

export const SpaceObjectCache = new Cache<SpaceObjectDTO>(
    async (ids) => {
        const { items } = await getSpaceObjects({
            ids,
        })
        return items
    },
    (item) => item.id
)

export function spaceObjectFormatter(s?: SpaceObjectDTO): string {
    if (!s) return "找不到地點"
    const m =
        s.mileage?.type === MileageType.Point
            ? mileageNumberFormatter(s.mileage.value)
            : s.mileage?.type === MileageType.Range
            ? `${mileageNumberFormatter(
                  s.mileage.start
              )} - ${mileageNumberFormatter(s.mileage.end)}`
            : ""
    return `${s.spaceObjectPath.namePath.reduce((a, b) => `${a} \\ ${b}`)} (${
        s.number
    }) ${m}`
}

/** 匯出空間物件 */
export async function saveSpaceObjects(items: SpaceObjectDTO[]) {
    const placeInfoRows = items.map((item) => [
        item.id,
        item.number,
        item.name,
        item.parentId ?? "",
        item.spaceObjectPath.namePath.join(" / "),
        item.type ? spaceObjectTypeFormatter(item.type) : "",
        item.category ?? "",
        (() => {
            if (item.mileage?.type === MileageType.Point)
                return item.mileage!.value?.toString() ?? ""
            else if (item.mileage?.type === MileageType.Range)
                return `${item.mileage!.start}, ${item.mileage!.end}`
            else return ""
        })(),
        item.coordinate ? `${item.coordinate.x}, ${item.coordinate.y}` : "",
        item.bimId ?? "",
        item.labels.map((label) => `${label.name}:${label.value}`).join(", "),
    ])

    const book = xlsx.utils.book_new()
    const placeInfoSheet = xlsx.utils.aoa_to_sheet([
        [
            "ID",
            "編號",
            "名稱",
            "所在位置ID",
            "所在位置",
            "類型",
            "分類",
            "里程",
            "座標",
            "BIM ID",
            "標籤",
        ],
        ...placeInfoRows,
    ])

    autofitColumns(placeInfoSheet)
    xlsx.utils.book_append_sheet(book, placeInfoSheet, "空間物件清單")

    const placeCustomPropertiesRows = items.flatMap((place) =>
        place.customProperties.map((p) => [place.id, p.name, p.value])
    )
    const placeCustomPropertiesSheet = xlsx.utils.aoa_to_sheet([
        ["ID", "自定義屬性名稱", "自定義屬性值"],
        ...placeCustomPropertiesRows,
    ])
    autofitColumns(placeCustomPropertiesSheet)
    xlsx.utils.book_append_sheet(
        book,
        placeCustomPropertiesSheet,
        "空間物件自定義屬性"
    )

    xlsx.writeFile(
        book,
        `空間物件清單_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`
    )
}

/** 註銷空間物件 */
export function putSpaceObjectDisabled(id: string, disabled = true) {
    return api.put(`api/SpaceObjects/${id}/IsDisabled`, {
        json: disabled,
    })
}

export enum QrCodeSortMethod {
    Id,
    Subject,
}

export interface QrCodeDTO {
    id: string
    subject: string
    navigationPlaceId: string | null
    unlockSettings: UnlockSetting[]
}

export interface UnlockSetting {
    placeId: string
    unlockLifetime: number
}

/** 查詢 QR Code */
export function getQrCodes(
    query: {
        skip?: number | null
        take?: number | null
        ids?: string[] | null
        keyword?: string | null
        sort?: QrCodeSortMethod | null
    } = {}
) {
    return api
        .get("api/QrCodes", {
            searchParams: buildParms(query),
        })
        .json<{
            total: number
            items: QrCodeDTO[]
        }>()
}

/** 取得 QR Code */
export function getQrCode(id: string) {
    return api.get(`api/QrCodes/${id}`).json<QrCodeDTO>()
}

export interface SetQrCodeInput {
    id?: string | null
    subject: string
    navigationPlaceId: string
    unlockSettings: UnlockSetting[]
}

/** 設定 QR Code */
export function setQrCode(input: SetQrCodeInput) {
    return api
        .put(`api/QrCodes`, {
            json: input,
        })
        .json<QrCodeDTO>()
}

/** 刪除空間物件 */
export function deleteQrCode(id: string) {
    return api.delete(`api/QrCodes/${id}`)
}

/** 匯出空間物件 */
export async function saveQrCodes(items: QrCodeDTO[]) {
    const ids = lodash(
        items
            .map((c) => c.navigationPlaceId)
            .concat(
                items
                    .map((c) => c.unlockSettings.map((s) => s.placeId))
                    .flatMap((x) => x)
            )
            .filter((id) => !!id)
    )
        .uniq()
        .value() as string[]

    await SpaceObjectCache.updateByIds(ids)

    const qrCodeRows = items.map((item) => {
        const navigationPlace = item.navigationPlaceId
            ? SpaceObjectCache.get(item.navigationPlaceId) ?? null
            : null
        return [
            item.id,
            item.subject,
            item.navigationPlaceId,
            navigationPlace?.spaceObjectPath.namePath.join(" / "),
        ]
    })
    const book = xlsx.utils.book_new()
    const sheetQrCode = xlsx.utils.aoa_to_sheet([
        ["ID", "主旨", "導覽地點ID", "導覽地點"],
        ...qrCodeRows,
    ])

    const unlockRows = lodash(items)
        .flatMap((c) =>
            c.unlockSettings.map((s) => {
                const unlockPlace = s.placeId
                    ? SpaceObjectCache.get(s.placeId) ?? null
                    : null
                return [
                    c.id,
                    s.placeId,
                    unlockPlace?.spaceObjectPath.namePath.join(" / "),
                ]
            })
        )
        .value()
    const sheetUnlock = xlsx.utils.aoa_to_sheet([
        ["ID", "解鎖地點ID", "解鎖地點"],
        ...unlockRows,
    ])

    autofitColumns(sheetQrCode)
    autofitColumns(sheetUnlock)
    xlsx.utils.book_append_sheet(book, sheetQrCode, "QR Code 清單")
    xlsx.utils.book_append_sheet(book, sheetUnlock, "解鎖清單")
    xlsx.writeFile(
        book,
        `QR Code 清單_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`
    )
}

/** 下載 QR Code 壓縮檔 */
export async function downloadQrCodeZip() {
    const response = await api.get(`api/QrCodes/Export`)
    const name = contentDisposition.parse(
        response.headers.get("content-disposition")!
    ).parameters.filename
    const blob = await response.blob()
    saveAs(blob, name)
}

/** 使用地點清單產製 QR Code 匯入表 */
export async function genQrCodeImportListFromPlaceList(file: File) {
    const formData = new FormData()
    formData.append("file", file)

    const response = await api.post(
        `api/QrCodes/GenImportQrCodeListFromPlaceList`,
        {
            body: formData,
        }
    )
    const name = contentDisposition.parse(
        response.headers.get("content-disposition")!
    ).parameters.filename
    const blob = await response.blob()
    saveAs(blob, name)
}
